<template>
  <div v-loading.fullscreen.lock="true">Callback</div>
</template>

<script>
import { onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "CallbackSpotify",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    onBeforeMount(async () => {
      const code = route.query.code;
      if (code) {
        await store.dispatch(
          "SpotifyAccessTokenHelperModule/setAccessTokenByCode",
          code
        );
        await router.push("/");
      }
    });
  },
};
</script>

<style scoped></style>
